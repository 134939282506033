import { Object3D, PanoGL, MeshMousePicker, MeshBasicMaterial, Mesh, EventLine, SphereBufferGeometry } from './ImportHelper'

class ProfileGeom extends Object3D {
    constructor (panogl, scalable, points) {
        super()
        this._panogl = panogl
        this._marks = []
        this._scalable = scalable
        this._openToModify = true
        this.pointer = new Mesh(new SphereBufferGeometry(2), new MeshBasicMaterial({ color: 0xffff00 }))
        this.add(this.pointer)
        this.setData(points, true)
        this._onDataChange = this._onDataChange.bind(this)
    }

    complete () {
        this._panogl.addEvent(PanoGL.DATA_CHANGE, this, this._onDataChange)
    }

    _onDataChange (e) {
        if (this._lastData) {
            const { data, smooth, color } = this._lastData
            this.setData(data, smooth, color)
        }
    }

    setData (data, smooth, color = 0xff00ff) {
        this._lastData = {
            data,
            smooth,
            color
        }

        let recentPoints = data.map((e) => ({ ...this._scalable.calculatePointPositionFromLonLatAlt(e.x, e.y, e.z), original: e }))

        if (smooth) {
            recentPoints = recentPoints.map((e, i) => i % 50 === 0 ? e : null).filter(e => e != null)
        }
        this.createLines(recentPoints, color)
    }

    getClosesetPoint () {
        return this._marks.map(e => e.original)
    }

    createLines (data, color = 0xff00ff) {
        this._cartesianPoints = data
        const pano = this._panogl
        if (this._clickLine) {
            this._clickLine.geometry.dispose()
            this._clickLine.material.dispose()
            this._clickLine.setClickable(false)
            this.remove(this._clickLine)

            this._visibleLine.geometry.dispose()
            this._visibleLine.material.dispose()
            this.remove(this._visibleLine)
            this._scalable.getBasePano().removeResizeMaterial(this._resizeMaterial)
        }

        this._resizeMaterial = new THREE.LineMaterial({
            color: color,
            linewidth: 2, // in pixels
            dashed: true,
            depthTest: true,
            depthWrite: false,
            opacity: 0,
            transparent: true
        })
        this._scalable.getBasePano().addResizeMaterial(this._resizeMaterial)

        let vertices = data.reduce((acc, { x, y, z }) => {
            acc.push(x, y, z)
            return acc
        }, [])

        let geom = new THREE.LineGeometry()
        let visibleLine = new THREE.Line2(geom, this._resizeMaterial)
        visibleLine.frustumCulled = false
        visibleLine.geometry.setPositions(vertices)
        visibleLine.computeLineDistances()
        this.add(visibleLine)
        visibleLine.position.set(0, 0, 0)
        this.position.set(0, 0, 0)

        let clickGeom = new THREE.BufferGeometry()
        var positions = new Float32Array(vertices)
        clickGeom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

        let clickMaterial = new THREE.LineBasicMaterial({
            color: new THREE.Color(1, 1, 0),
            linewidth: 1,
            depthTest: true,
            opacity: 0,
            transparent: true,
            depthWrite: false
        })

        let clickLine = new EventLine(clickGeom, clickMaterial)
        this.add(clickLine)
        let camera = pano.getMainCamera()
        clickLine.setClickable(true, pano, null, camera)
        clickLine.addEvent(MeshMousePicker.CLICK, this, this._onClick)
        this._clickLine = clickLine
        this._visibleLine = visibleLine
    }

    showLineCursor () {
        this.pointer.visible = true
    }

    hideLineCursor () {
        this.pointer.visible = false
    }

    _onClick ({ point }) {
        let closest = this._cartesianPoints
            .reduce((acc, curr) => {
                let dx = acc.refPoint.x - curr.x
                let dy = acc.refPoint.y - curr.y
                let dz = acc.refPoint.z - curr.z

                let dist = Math.sqrt(dx * dx + dy * dy + dz * dz)

                if (dist < acc.dist) {
                    acc.dist = dist
                    acc.selected = curr
                    acc.x = curr.x
                    acc.y = curr.y
                    acc.z = curr.z
                }

                return acc
            }, { dist: Number.MAX_SAFE_INTEGER, ...point, refPoint: point })

        if (closest.selected) {
            this._marks.push(closest.selected)
        }

        this.dispatchEvent({ type: 'onClick', target: this, point })
    }

    hide () {
        this._clickLine.setClickable(false)
        this.visible = false
    }

    show () {
        let panogl = this._panogl
        let camera = panogl.getMainCamera()
        this._clickLine.setClickable(true, panogl, null, camera)
        this.visible = true
    }

    get isOpen () {
        return this._openToModify
    }

    setLabelAt (location) {
        let pos = this._scalable.calculatePointPositionFromLonLatAlt(location.lon, location.lat, location.alt)
        this.pointer.position.set(pos.x, pos.y, pos.z)
        this._panogl.setDirty()
    }

    destroy () {
        let visibleLine = this._visibleLine
        if (visibleLine) {
            this.remove(visibleLine)
            visibleLine.geometry.dispose()
            visibleLine.material.dispose()
        }

        let clickLine = this._clickLine
        if (clickLine) {
            this.remove(clickLine)
            clickLine.geometry.dispose()
            clickLine.material.dispose()
            clickLine.setClickable(false)
        }
    }
}

export { ProfileGeom }
