import { ProfileGeom } from './ProfileGeom'
import { CursorManager, PanoGL, Mesh, SphereBufferGeometry, MeshBasicMaterial } from './ImportHelper'

class ProfilePlugin extends AnkaPanAPI.PanoPlugin {
    constructor (options) {
        super()
        this._url = options.url
        this._profileGeoms = []
        this.cloneable = false
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onSelectProfile = this.onSelectProfile.bind(this)
    }

    prepare () {
        let pano = this.baseObject
        let scalable = pano.getScalable()
        let scalableScene = scalable.scalableScene

        let material = new MeshBasicMaterial({ color: 0xffff00 })
        let mark1 = new Mesh(new SphereBufferGeometry(2), material)
        let mark2 = new Mesh(new SphereBufferGeometry(2), material)
        scalableScene.add(mark1)
        scalableScene.add(mark2)

        mark1.visible = false
        mark2.visible = false

        this._marks = [
            mark1,
            mark2
        ]
    }

    onDataChange (e) {
        this.clear()
        this.loadCurrentData()
    }

    onKeyDown (e) {
        if (e.keyCode === 27) {
            this.stop()
            this.clear()
        }
    }

    stop () {
        if (this._started) {
            this._started = false
            CursorManager.setCursor('default', -1, true)
            document.removeEventListener('keydown', this.onKeyDown)
            this.baseObject.removeEvent(PanoGL.LOCATION_CHANGE, this.onDataChange)
            this.throwEvent({ type: ProfilePlugin.END })
        }
    }

    clear () {
        this._marks.forEach(e => (e.visible = false))
        this._selectedProfile = null
        this._profileGeoms.forEach(profileGeom => {
            if (profileGeom.parent) profileGeom.parent.remove(profileGeom)
            profileGeom.destroy()
        })
        this._profileGeoms.length = 0
    }

    loadCurrentData () {
        let pano = this.baseObject
        let { lon, lat, stamp } = pano.getCurrentPoint()
        // gid, let date = new Date(stamp)
        // let secondStamp = ((date.getHours() * 60) + date.getMinutes()) * 60 + date.getSeconds()
        let serviceID = '*'
        // let url = this._url + `/profiles/${serviceID}/${lon}/${lat}`
        let url = this._url + `/gtbys/profiles/bystamp/${serviceID}/${lon}/${lat}/${stamp}`

        CursorManager.setCursor('progress', 777)
        this.throwEvent({ type: ProfilePlugin.LOADING_START })
        AnkaPanAPI.AnkaFetch(url)
            .then(e => e.json())
            .then(({ success, data }) => {
                if (!this._started) return

                // let gid = pano.getCurrentPoint()
                // if (currentID === this._currentID) return

                if (success) {
                    if (Array.isArray(data)) {
                        this._currentData = data
                        data.forEach((points, index) => {
                            points = points.concat().splice(20, points.length - 40)
                            let scalable = pano.getScalable()
                            let profileGeom = new ProfileGeom(pano, scalable, points)
                            profileGeom.profileID = index
                            profileGeom.addEventListener('onClick', this.onSelectProfile)
                            let scalableScene = scalable.scalableScene
                            scalableScene.add(profileGeom)
                            this._profileGeoms.push(profileGeom)
                        })
                    } else {
                        console.log('data is not array')
                    }
                } else {
                    console.log('No data found')
                }

                this.setDirty()
                CursorManager.setCursor('default', -1, true)
                this.throwEvent({ type: ProfilePlugin.LOADING_END })
            })
            .catch(e => {
                console.log(e)
                CursorManager.setCursor('default', -1, true)
                this.throwEvent({ type: ProfilePlugin.LOADING_END })
            })
    }

    getCurrentProfile () {
        return this._selectedProfile
    }

    onSelectProfile (e) {
        let profile = e.target
        if (this._selectedProfile) {
            let mark = this._marks[1]
            mark.position.set(e.point.x, e.point.y, e.point.z)
            mark.visible = true
            let point = profile.getClosesetPoint()
            let id = profile.profileID
            let gpstimes = point.map(e => parseFloat(e.gpstime))

            let minGPSTime = Math.min(...gpstimes)
            let maxGPSTime = Math.max(...gpstimes)

            let selectedData = this._currentData[id]
            let filtered = selectedData.filter(e => {
                return parseFloat(e.gpstime) >= minGPSTime && parseFloat(e.gpstime) < maxGPSTime
            })

            this._marks.forEach(m => (m.visible = false))
            profile.setData(filtered, false, 0xff9a03)
            profile.complete()
            this.stop()
            this.throwEvent({ type: ProfilePlugin.COMPLETE, profileGeom: profile, cropData: filtered.map(e => ({ ...e })), rawData: selectedData.map(e => ({ ...e })) })
        } else {
            this._profileGeoms.forEach((p) => {
                if (p === profile) {
                    p.show()
                    let mark = this._marks[0]
                    mark.position.set(e.point.x, e.point.y, e.point.z)
                    mark.visible = true
                    this._selectedProfile = profile
                } else {
                    p.hide()
                }
            })
        }
    }

    start () {
        this._started = true
        document.addEventListener('keydown', this.onKeyDown)
        this.baseObject.addEvent(PanoGL.LOCATION_CHANGE, this, this.onDataChange)
        this.clear()
        this.loadCurrentData()
    }
}

ProfilePlugin.COMPLETE = 'complete'
ProfilePlugin.END = 'onEnd'
ProfilePlugin.LOADING_START = 'onLoadingStart'
ProfilePlugin.LOADING_END = 'onLoadingEnd'

export { ProfilePlugin }
